import {Box, Button, Flex, Grid, Link, StackDivider, Text, VStack} from '@chakra-ui/react'
import {useContext} from "react";
import AppContext from "../AppContext";

function Main() {
    const {currentMovie, timer, pickMovie, appInsights} = useContext(AppContext)

    return (
        <Grid px={4}
              py={20}
        >
            <VStack
                divider={<StackDivider borderColor='gray.200'/>}
                spacing={4}
                align='center'
                minH="calc(100vh - 350px)"
            >
                <Text fontSize={"3xl"}>{currentMovie}</Text>
                <Box>
                    {timer === 0 && (<Text>Time is up!</Text>)}
                    {timer === 1 && (<Flex align="center"><Text fontSize={"xl"}>{timer}&nbsp;</Text><Text>second
                        left</Text></Flex>)}
                    {timer > 1 && (<Flex align="center"><Text fontSize={"xl"}>{timer}&nbsp;</Text><Text>seconds
                        left</Text></Flex>)}
                </Box>
            </VStack>
            <VStack>
                <Button
                    colorScheme='blue'
                    size='lg'
                    onClick={pickMovie}
                    width={300}>
                    Generate
                </Button>
                <Text fontSize="xs">Made with &#x2764; by <Link onClick={() => appInsights.trackEvent({ name: 'Cloudexible' })} href="https://www.cloudexible.com" color="blue.900" isExternal>Cloudexible</Link></Text>
            </VStack>
        </Grid>
    );
}

export default Main;
