import {ChakraProvider} from '@chakra-ui/react'
import Main from "./components/Main";
import Layout from "./components/Layout";
import {useEffect, useState} from "react";
import moviesData from "./assets/movies.json";
import AppContext from "./AppContext";
import {ApplicationInsights} from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
    config: {
        connectionString: 'InstrumentationKey=4511e502-94bc-4d28-b293-2b4e18f2446b;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/',
    }
});
appInsights.loadAppInsights();

function App() {
    const [selectedReleaseYear, setSelectedReleaseYear] = useState('all')
    const [selectedDuration, setSelectedDuration] = useState(60)
    const [movies, setMovies] = useState(Object.values(moviesData).flat())
    const [currentMovie, setCurrentMovie] = useState(movies[Math.floor(Math.random() * movies.length)])
    const [timer, setTimer] = useState(60)

    useEffect(() => {
        appInsights.trackPageView()
    }, [])

    useEffect(() => {
        setTimer(selectedDuration)
        const interval = setInterval(() => {
            setTimer(timer => {
                if (timer === 1) clearInterval(interval)
                return timer - 1
            })
        }, 1000)
        return () => clearInterval(interval)
    }, [selectedDuration, currentMovie])

    const pickMovie = () => {
        const movie = movies[Math.floor(Math.random() * movies.length)]
        appInsights.trackEvent({name: 'Generate'}, { movie })
        setCurrentMovie(movie)
    }

    useEffect(() => {
        if (selectedReleaseYear === 'all') setMovies(Object.values(moviesData).flat())
        else setMovies(moviesData[selectedReleaseYear])
    }, [selectedReleaseYear])

    return (
        <ChakraProvider>
            <AppContext.Provider value={{
                selectedReleaseYear,
                selectedDuration,
                setSelectedReleaseYear,
                setSelectedDuration,
                currentMovie,
                timer,
                pickMovie,
                appInsights
            }}>
                <Layout setReleaseYear={setSelectedReleaseYear}>
                    <Main/>
                </Layout>
            </AppContext.Provider>
        </ChakraProvider>
    );
}

export default App;
