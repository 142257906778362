import React, {useContext} from 'react';
import {
    IconButton,
    Box,
    CloseButton,
    Flex,
    useColorModeValue,
    Drawer,
    DrawerContent,
    Text,
    useDisclosure, Select,
} from '@chakra-ui/react';
import {
    FiMenu,
} from 'react-icons/fi';
import AppContext from "../AppContext";

export default function Layout({children}) {
    const {isOpen, onOpen, onClose} = useDisclosure();
    return (
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
            <SidebarContent
                onClose={() => onClose}
                display={{base: 'none', md: 'block'}}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent onClose={onClose}/>
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav display={{base: 'flex', md: 'none'}} onOpen={onOpen}/>
            <Box ml={{base: 0, md: 60}} p="4">
                {children}
            </Box>
        </Box>
    );
}

const SidebarContent = ({onClose, ...rest}) => {
    const { setSelectedReleaseYear, selectedReleaseYear, selectedDuration, setSelectedDuration } = useContext(AppContext);
    return (
        <Box
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{base: 'full', md: 60}}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <Text fontSize="xl" fontWeight="bold">
                    Bollywood Charades
                </Text>
                <CloseButton display={{base: 'flex', md: 'none'}} onClick={onClose}/>
            </Flex>
            <Box p={4} mx={4}>
                <Text fontSize="md" fontWeight="bold">Release Year</Text>
                <Select value={selectedReleaseYear} variant='flushed' onChange={e => setSelectedReleaseYear(e.target.value)}>
                    <option value="all">All</option>
                    <option value="1991-2000">1991-2000</option>
                    <option value="2001-2010">2001-2010</option>
                    <option value="2011-2020">2011-2020</option>
                </Select>
            </Box>
            <Box
                p="4"
                mx="4"
            >
                <Text fontSize="md" fontWeight="bold">Timer Duration</Text>
                <Select value={selectedDuration} variant='flushed' onChange={e => setSelectedDuration(Number.parseInt(e.target.value))}>
                    <option value={60}>1 minute</option>
                    <option value={120}>2 minutes</option>
                    <option value={180}>3 minutes</option>
                    <option value={-1}>No Timer</option>
                </Select>
            </Box>
        </Box>
    );
};

// const NavItem = ({icon, children, ...rest}) => {
//     return (
//         <Link href="#" style={{textDecoration: 'none'}} _focus={{boxShadow: 'none'}}>
//             <Flex
//                 align="center"
//                 p="4"
//                 mx="4"
//                 borderRadius="lg"
//                 role="group"
//                 cursor="pointer"
//                 _hover={{
//                     bg: 'cyan.400',
//                     color: 'white',
//                 }}
//                 {...rest}>
//                 {icon && (
//                     <Icon
//                         mr="4"
//                         fontSize="16"
//                         _groupHover={{
//                             color: 'white',
//                         }}
//                         as={icon}
//                     />
//                 )}
//                 {children}
//             </Flex>
//         </Link>
//     );
// };

const MobileNav = ({onOpen, ...rest}) => {
    return (
        <Flex
            ml={{base: 0, md: 60}}
            px={{base: 4, md: 24}}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent="flex-start"
            {...rest}>
            <IconButton
                variant="outline"
                onClick={onOpen}
                aria-label="open menu"
                icon={<FiMenu/>}
            />

            <Text fontSize="2xl" ml="8" fontWeight="bold">
                Bollywood Charades
            </Text>
        </Flex>
    );
};